import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Dropdown, Row } from 'react-bootstrap'
import Image from 'react-bootstrap/Image'
import saayaLogo from '../Assets/Images/saaya logo.svg'
import appstore from '../Assets/Images/appstore.png'
import playstore from '../Assets/Images/playstore.png'
import phone from '../Assets/Images/phone.svg'
import video from '../Assets/Images/video.svg'
import whatsapp from '../Assets/Images/whatsapp.svg'
import english from '../Assets/Images/english.svg'
import 'react-lazy-load-image-component/src/effects/blur.css';
import ModalVideo from 'react-modal-video'
import Swal from 'sweetalert2'
import { Blogs, Languages, YTlinks, Images, Constants, ToolBox } from '../Helper'
import LinksModal from '../Components/LinksModal'
import MentalHealth from '../Assets/Announcement - World Mental Health Day Saaya.pdf'
import DropdownItem from 'react-bootstrap/esm/DropdownItem'

export default function LandingPage() {
    const [isOpen, setOpen] = useState(false)
    const [value, setValue] = useState('English');
    const [country, setCountry] = useState(english);
    const [code, setCode] = useState('');
    const accessCode = 'aramco'
    const [data, setData] = useState(Blogs.english);
    const [toolbox, setToolBox] = useState(ToolBox.english)
    const [show, setShow] = useState(false);
    const [linksLang, setLinksLang] = useState(YTlinks.english);
    const [embedId, setEmbedId] = useState();
    const [current_lang, setCurrentLang] = useState(Constants.english);
    const [openLinkModal, setOpenLinkModal] = useState(false);
    const [filters, setFilters] = useState("")
    const [filtersOption, setFiltersOption] = useState([current_lang.videos_txt, current_lang.blogs_txt, current_lang.toolbox_talks])

    const codeChecker = (e) => {
        if (code.length !== 0) {
            let trimmedCode = code.trim()
            if (trimmedCode.toLowerCase() === accessCode.toLowerCase()) {
                setShow(true)
            } else {
                alert('Invalid Access Code')
            }
        }
    }

    const filterChangeHandler = (selectedFilter) => {
        setFilters(selectedFilter);
        if (selectedFilter === current_lang.videos_txt) {
            let tempData = []
            setData([]);
            setToolBox([])
            if (value === 'English') {
                YTlinks.english.forEach(data => !data.link && tempData.push(data))
                setLinksLang(tempData)
            } else if (value === 'اردو') {
                YTlinks.urdu.forEach(data => !data.link && tempData.push(data))
                setLinksLang(tempData)
            } else if (value === 'عربى') {
                YTlinks.arabic.forEach(data => !data.link && tempData.push(data))
                setLinksLang(tempData)
            } else if (value === 'বাংলা') {
                YTlinks.bangali.forEach(data => !data.link && tempData.push(data))
                setLinksLang(tempData)
            } else if (value === 'हिन्दी') {
                YTlinks.hindi.forEach(data => !data.link && tempData.push(data))
                setLinksLang(tempData)
            } else if (value === 'Tagalog') {
                YTlinks.tagalog.forEach(data => !data.link && tempData.push(data))
                setLinksLang(tempData)
            } else if (value === '中国人') {
                YTlinks.chinese.forEach(data => !data.link && tempData.push(data))
                setLinksLang(tempData)
            }
        } else if (selectedFilter === current_lang.blogs_txt) {
            setLinksLang([]);
            setToolBox([])
            if (value === 'English') {
                setData(Blogs.english)
            } else if (value === 'اردو') {
                setData(Blogs.urdu)
            } else if (value === 'عربى') {
                setData(Blogs.arabic)
            } else if (value === 'বাংলা') {
                setData(Blogs.bangali)
            } else if (value === 'हिन्दी') {
                setData(Blogs.hindi)
            } else if (value === 'Tagalog') {
                setData(Blogs.tagalog)
            } else if (value === '中国人') {
                setData(Blogs.chinese)
            }
        } else if (selectedFilter === current_lang.toolbox_talks) {
            setLinksLang([]);
            setData([])
            if (value === 'English') {
                setToolBox(ToolBox.english)
            } else if (value === 'اردو') {
                setToolBox(ToolBox.english)
            } else if (value === 'عربى') {
                setToolBox(ToolBox.english)
            } else if (value === 'বাংলা') {
                setToolBox(ToolBox.english)
            } else if (value === 'हिन्दी') {
                setToolBox(ToolBox.english)
            } else if (value === 'Tagalog') {
                setToolBox(ToolBox.english)
            } else if (value === '中国人') {
                setToolBox(ToolBox.english)
            }
        } else {
            if (value === 'English') {
                setToolBox(ToolBox.english)
                setData(Blogs.english)
                setLinksLang(YTlinks.english)
                setCurrentLang(Constants.english)
            } else if (value === 'اردو') {
                setToolBox(ToolBox.english)
                setData(Blogs.urdu)
                setLinksLang(YTlinks.urdu)
                setCurrentLang(Constants.urdu)
            } else if (value === 'عربى') {
                setToolBox(ToolBox.english)
                setData(Blogs.arabic)
                setLinksLang(YTlinks.arabic)

                setCurrentLang(Constants.arabic)
            } else if (value === 'বাংলা') {
                setToolBox(ToolBox.english)
                setData(Blogs.bangali)
                setLinksLang(YTlinks.bangali)

                setCurrentLang(Constants.bangali)
            } else if (value === 'हिन्दी') {
                setToolBox(ToolBox.english)
                setData(Blogs.hindi)
                setLinksLang(YTlinks.hindi)

                setCurrentLang(Constants.hindi)
            } else if (value === 'Tagalog') {
                setToolBox(ToolBox.english)
                setData(Blogs.tagalog)
                setLinksLang(YTlinks.tagalog)

                setCurrentLang(Constants.tagalog)
            } else if (value === '中国人') {
                setToolBox(ToolBox.english)
                setData(Blogs.chinese)
                setLinksLang(YTlinks.chinese)
                setCurrentLang(Constants.chinese)
            }
        }
    }

    useEffect(() => {
        setFiltersOption([current_lang.videos_txt, current_lang.blogs_txt, current_lang.toolbox_talks])
    }, [current_lang])

    const handleChange = (item) => {
        setValue(item.name)
        setCountry(item.image)
        setFilters("");
        if (item.name === 'English') {
            setData(Blogs.english)
            setLinksLang(YTlinks.english)
            setToolBox(ToolBox.english)
            setCurrentLang(Constants.english)
        } else if (item.name === 'اردو') {
            setToolBox(ToolBox.english)
            setData(Blogs.urdu)
            setLinksLang(YTlinks.urdu)

            setCurrentLang(Constants.urdu)
        } else if (item.name === 'عربى') {
            setToolBox(ToolBox.english)
            setData(Blogs.arabic)
            setLinksLang(YTlinks.arabic)

            setCurrentLang(Constants.arabic)
        } else if (item.name === 'বাংলা') {
            setData(Blogs.bangali)
            setToolBox(ToolBox.english)
            setLinksLang(YTlinks.bangali)

            setCurrentLang(Constants.bangali)
        } else if (item.name === 'हिन्दी') {
            setToolBox(ToolBox.english)
            setData(Blogs.hindi)
            setLinksLang(YTlinks.hindi)

            setCurrentLang(Constants.hindi)
        } else if (item.name === 'Tagalog') {
            setToolBox(ToolBox.english)
            setData(Blogs.tagalog)
            setLinksLang(YTlinks.tagalog)

            setCurrentLang(Constants.tagalog)
        } else if (item.name === '中国人') {
            setData(Blogs.chinese)
            setLinksLang(YTlinks.chinese)
            setCurrentLang(Constants.chinese)
        }
    };

    const imgAction = (img) => {
        window.open(img, '_blank');
    }

    window.onbeforeunload = function () {
        setCode('')
        setShow(false)
        document.cookie = "accessCode=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }

    const handleVidOpen = (id) => {
        const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
        const body = document.body;
        body.style.height = '100vh';
        body.style.overflowY = 'hidden';
        setOpen(true)
        setEmbedId(id)
    }

    const handleVidClose = () => {
        setOpen(false)
        const body = document.body;
        const scrollY = body.style.top;
        body.style.position = '';
        body.style.top = '';
        body.style.height = '';
        body.style.overflowY = '';
    }

    return (
        <div className='main hero-image'>
            <Container fluid className='container_main'>
                <Row className='hero notVisible'>
                    <div className={`bgDiv transitionSize ${!show && 'fullScreenTemp '}`}>
                    </div>
                    <div className='saayaLogo posAbs'>
                        <img className={`${show ? 'opacityVisibleWithTransition' : 'opacityZero'}`} src={saayaLogo} alt='img22' />
                    </div>
                    <Col xs={12} className='height570 displayFlex centerheight heading ml0'>
                        {show ?
                            <>
                                <p className='header'>{current_lang.heading}</p>
                                <p className='secondary'>{current_lang.subHeading}</p>
                            </>
                            :
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1" className='fontSize22'>Enter Access code</label>
                                <input
                                    autoComplete='new-password'
                                    autoFocus
                                    value={code}
                                    onKeyPress={e => e.key === 'Enter' && codeChecker(code)}
                                    onClick={() => window.scrollTo(0, 0)}
                                    onChange={(e) => setCode(e.target.value)}
                                    type="text"
                                    className="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    placeholder="Enter Code"
                                />
                                <button disabled={code.length === 0} className="btn btn-primary" onClick={() => codeChecker()}>Enter</button>
                            </div>
                        }
                    </Col>
                </Row>

            </Container>
            {show ?
                <>
                    <Container>
                        <Row className='mt-4 mt-md-0 mb-4 p-4 bg-black bg-opacity-75 text-light'>
                            <Col>
                                {/* <h2 className='text-center p-2'>World Mental Health Day</h2> */}
                                <p className='text-center'>
                                Prolonged stress can affect your physical and mental health and wellbeing. As November 6th is Stress Awareness Day, please reach out to us if you are feeling stressed. All Saudi Aramco Contractors have access to free 24/7 multilingual mental well-being services including a call center, video counseling sessions, and immediate WhatsApp messaging connecting them with trained counselors. To access the Hot-line call 800-8500-619, to schedule a video session <a href='https://wellness.saayahealth.com/' target='_blank'>click here</a> and to send a WhatsApp <a href='https://api.whatsapp.com/message/KJB7UMMYXUFEO1' target='_blank'>click here</a>.

                                    {/* Your Mental Well-being is important. To measure your Mental Well-being <a href='https://form.typeform.com/to/NB5Ik0mo' target="_blank">click here</a>. */}
                                </p>
                            </Col>
                        </Row>
                    </Container>

                    <Container className='buttonContainer'>
                        {openLinkModal &&
                            <LinksModal
                                setOpenLinkModal={setOpenLinkModal}
                                webPortal={current_lang.web_portal}
                                android={current_lang.android_portal}
                                ISO={current_lang.iOS_portal}
                            />}
                        <Row className='buttonRow'>
                            <Col sm className='buttonColumn d-grid'>
                                <Button onClick={() => Swal.fire({
                                    text: current_lang.contact_us,
                                    confirmButtonText: current_lang.okay
                                })}>
                                    <Image src={phone} />
                                    {current_lang.hotline_btn}
                                </Button>
                                <a onClick={() => setOpenLinkModal(true)} rel="noreferrer noopener">
                                    <Button>
                                        <Image src={video} />
                                        {current_lang.vid_call_btn}
                                    </Button>
                                </a>
                                <a rel="noreferrer noopener" target="_blank" href='https://wa.me/message/KJB7UMMYXUFEO1'>
                                    <Button>
                                        <Image src={whatsapp} />
                                        {current_lang.whatsApp_btn}
                                    </Button>
                                </a>
                            </Col>
                        </Row>
                        <Row className='dropdownRow w-75'>
                            <div className="dropdown">
                                {/* conditinal classname if value contains any value from array */}

                                <Row className='buttonRow border-1'>
                                    <Col className={`buttonColumn d-flex flex-column flex-md-row justify-content-center ${['عربى', 'اردو'].includes(value) ? 'flex-row-reverse' : ''}`}>
                                        <Dropdown>
                                            <div className={`labelLanguage ${['عربى', 'اردو'].includes(value) ? 'alignRight' : ''}`}>{current_lang.choose_lang}</div>
                                            <Dropdown.Toggle className='toggle' variant="success" id="dropdown-basic">
                                                {value === '' ? 'Select Language' : (
                                                    <>
                                                        <div className='toggleDiv'>
                                                            <Image width={'30px'} src={country} /> {value}
                                                        </div>
                                                    </>
                                                )}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                {Languages?.map((item, index) => (
                                                    <Dropdown.Item onClick={(e) => handleChange(item, e)} key={'111' + index + item}>
                                                        <Image width={'30px'} src={item.image} /> {item.name}
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>

                                        <Dropdown>
                                            <div className={`labelLanguage ${['عربى', 'اردو'].includes(value) ? 'alignRight' : ''}`}>{current_lang.choose_cat}</div>
                                            <Dropdown.Toggle className='toggle' variant="success" id="dropdown-basic">
                                                {filters === '' ? current_lang.select_category : (
                                                    <>
                                                        <div className='toggleDiv'>
                                                            {filters}
                                                        </div>
                                                    </>
                                                )}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                {filters && <Dropdown.Item onClick={(e) => filterChangeHandler("", e)}>{current_lang.none_txt}</Dropdown.Item>}
                                                {filtersOption?.map((item, index) => (
                                                    <Dropdown.Item onClick={(e) => filterChangeHandler(item, e)} key={'111' + index + item}>
                                                        {item}
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>
                                </Row>
                            </div>
                        </Row>
                        <Row className='cardRow d-grid'>
                            {linksLang?.map((item, index) => (
                                item.embedId ?
                                    <Col key={item + index}>
                                        <div
                                            onClick={() => handleVidOpen(item.embedId)}
                                            className="card bg-dark text-white min100"
                                        >
                                            <img
                                                className='card-img pointerCursor'
                                                src={`https://img.youtube.com/vi/${item.embedId}/hqdefault.jpg`}
                                            />
                                            <div className="card-img-overlay">
                                                <h5 className="card-title">
                                                    <img className='ytIconImg' src={Images.yt_icon} />{item.title}
                                                </h5>
                                            </div>
                                        </div>
                                    </Col>
                                    : <></>
                            )).reverse()}

                            {linksLang?.map((item, index) => (
                                item.link ?
                                    <Col key={'222' + item + index + item.title}>
                                        <div
                                            // Inline Style Background Color with
                                            onClick={() => window.open(item.link, '_blank')}
                                            className="urlIcon card text-white min100 "
                                        >
                                            <img
                                                className='card-img pointerCursor '
                                                src={item.dImg}
                                            />
                                            <div className="card-img-overlay">
                                                <h5 className="card-title">
                                                    {item.title}
                                                </h5>
                                            </div>
                                        </div>
                                    </Col>
                                    : <></>
                            )).reverse()}

                            {data?.map((item, index) => (
                                <Col key={'333' + item + index}>
                                    <div className="card bg-dark text-white min100" onClick={() => imgAction(item.image_hd)}>
                                        <img className='card-img' src={item.image} alt={item.title} />
                                        <div className="card-img-overlay">
                                            <h5 className="card-title">{item.title}</h5>
                                        </div>
                                    </div>
                                </Col>
                            )).reverse()}
                            
                             {toolbox?.map((item, index) => (
                                <Col key={'333' + item + index}>
                                    <div className="card text-white min100" onClick={() => imgAction(item.image_hd)}>
                                        <img className='card-img' src={item.image} alt={item.title} />
                                        <div className="card-img-overlay">
                                            <h5 className="card-title">{item.title}</h5>
                                        </div>
                                    </div>
                                </Col>
                            )).reverse()}


                            <div className={`video-responsive ${!isOpen && 'hide'}`}>
                                <ModalVideo
                                    channel='youtube'
                                    autoplay={true}
                                    isOpen={isOpen}
                                    videoId={embedId}
                                    onClose={() => handleVidClose()}
                                />
                            </div>
                        </Row>
                        {/* <Row className='moreBlogRow'>
                        <div className="col-12 text-center my-4">
                            <a href="https://saayahealth.com/index.php/saaya-blog" className="btn mb-md-5"><span className="text-light">More Blogs</span></a>
                        </div>
                    </Row> */}
                    </Container>
                    <Container>
                        <Row>
                            <Col className='icons-container'>
                                <a target="_blank" href='https://apps.apple.com/pk/app/contractor-well-being-program/id1627179382'>
                                    <Image
                                        className='app-icon app-store'
                                        src={appstore}
                                    />
                                </a>
                                <a target="_blank" href='https://play.google.com/store/apps/details?id=cwp.saaya.android'>
                                    <Image
                                        className='app-icon play-store'
                                        src={playstore}
                                    />
                                </a>
                            </Col>
                        </Row>
                    </Container>
                </>
                : (<></>)}
        </div>
    )
}